<template>
    <v-data-table
        :headers="headers"
        :items="versions"
        item-key="index"
        class="elevation-0"
        :mobile-breakpoint="0"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-row
                no-gutter
                class="mt-0 mx-0 pt-4 pb-0 pb-4"
                style="background-color: #eeeeee"
            >
                <v-col cols="12" class="d-flex mb-n3">
                    <h1 class="mr-4">LOGS</h1>
                </v-col>
            </v-row>
        </template>
        <!--ITEMS-->
        <template v-slot:[`item.updatedOn`]="{ item }">
            <p class="my-0">
                {{ formatDate(item) }}
            </p>
        </template>
        <template v-slot:[`item.modifier`]="{ item }">
            <p class="my-0">
                {{
                    typeof item.modifier == 'string'
                        ? `${item.modifier}`
                        : item.modifier != null
                        ? `${item.modifier.name} (${item.modifier.role})`
                        : ''
                }}
            </p>
        </template>
        <template v-slot:[`item.changes`]="{ item }">
            <p
                class="my-0 font-weight-black"
                v-for="(key, index) of Object.keys(item.changes || {})"
                :key="index"
            >
                {{ key }}:
                <span class="font-weight-regular">{{ item.changes[key] }}</span>
            </p>
        </template>
    </v-data-table>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'

export default {
    name: 'QuoteLogs',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        statusColor: {
            OPEN: 'primary',
            AWARDED: 'success',
            LOST: 'error',
            SENT: 'warning',
            APPROVAL: 'orange',
            CLOSED: 'black',
        },
        loading: false,
        logs: [],
        headers: [
            {
                text: 'DATE',
                value: 'updatedOn',
                align: 'center',
                sortable: false,
                width: '200',
            },
            {
                text: 'MODIFIED BY',
                value: 'modifier',
                align: 'center',
                sortable: false,
                width: '200',
            },
            {
                text: 'CHANGES',
                value: 'changes',
                align: 'left',
                sortable: false,
            },
        ],
        users: [],
        versions: [],
        quotes: [],
    }),
    async created() {
        try {
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.quotes = await API.getLiteQuotes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    async mounted() {
        try {
            this.loading = true
            await this.getProjectChangelog()
            this.handleDifferences()
            this.autocompleteData()
            this.sortData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        sortData() {
            this.versions.sort(
                (a, b) =>
                    (b.updatedOn
                        ? b.updatedOn._seconds
                        : b.createdOn._seconds) -
                    (a.updatedOn ? a.updatedOn._seconds : a.createdOn._seconds)
            )
        },
        autocompleteData() {
            this.versions.forEach(log => {
                log.modifier = this.getUser(log.modifier)
                if (log.changes) {
                    if (
                        log.changes.collaborators &&
                        log.changes.collaborators.length > 0
                    ) {
                        log.changes.collaborators = log.changes.collaborators
                            .map(collaborator => {
                                return this.getUser(collaborator).name
                            })
                            .join(', ')
                    }
                    if (log.changes.quotes && log.changes.quotes.length > 0) {
                        log.changes.quotes = log.changes.quotes
                            .map(quote => {
                                return this.getQuote(quote).name
                            })
                            .join(', ')
                    }
                    if (log.changes.accountManager) {
                        const user = this.getUser(log.changes.accountManager)
                        log.changes.accountManager =
                            typeof user == 'string'
                                ? user
                                : user
                                ? user.name
                                : ''
                    }
                    if (log.changes.projectManager) {
                        const user = this.getUser(log.changes.projectManager)
                        log.changes.projectManager =
                            typeof user == 'string'
                                ? user
                                : user
                                ? user.name
                                : ''
                    }
                    if (log.changes.price) {
                        log.changes.price = this.formatNumber(log.changes.price)
                    }
                }
            })
        },
        handleDifferences() {
            let current = undefined
            let versions = []
            this.logs.forEach(log => {
                if (!current) {
                    current = log
                    versions.push(log)
                } else {
                    let changes = this.projectDifference(current, log)
                    if (Object.keys(changes).length > 0) {
                        versions.push({ ...log, changes })
                        current = log
                    }
                }
            })
            this.versions = versions
        },
        async getProjectChangelog() {
            try {
                this.logs = await API.getProjectLogs(this.id)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        projectDifference(originalProject, newProject) {
            let oldProject = {
                archive: originalProject.archive,
                collaborators: originalProject.collaborators,
                quotes: originalProject.quotes,
                currency: originalProject.currency,
                price: originalProject.price,
                name: originalProject.name,
                reference: originalProject.reference,
                accountManager: originalProject.accountManager,
                projectManager: originalProject.projectManager,
            }
            let currentProject = {
                archive: newProject.archive,
                collaborators: newProject.collaborators,
                quotes: newProject.quotes,
                currency: newProject.currency,
                price: newProject.price,
                name: newProject.name,
                reference: newProject.reference,
                accountManager: newProject.accountManager,
                projectManager: newProject.projectManager,
            }
            let changes = {}
            Object.keys(currentProject).forEach(key => {
                if (
                    (oldProject[key] === undefined &&
                        currentProject[key] !== undefined) ||
                    (currentProject[key] !== undefined &&
                        JSON.stringify(currentProject[key]) !==
                            JSON.stringify(oldProject[key]))
                ) {
                    changes[key] = currentProject[key]
                }
            })
            return changes
        },
        formatDate(item) {
            let time = item.updatedOn
                ? item.updatedOn._seconds +
                  Number(`0.${item.updatedOn._nanoseconds}`)
                : item.createdOn._seconds +
                  Number(`0.${item.createdOn._nanoseconds}`)
            return moment.unix(time).format('YYYY-MM-DD, h:mm:ss a')
        },
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(
                Math.ceil(number != undefined ? number : 0)
            )
        },
        getUser(userId) {
            const user = this.users.find(user => user.id == userId)
            return user || userId
        },
        getQuote(quoteId) {
            const quote = this.quotes.find(quote => quote.id == quoteId)
            return quote || quoteId
        },
    },
}
</script>

<style>
thead {
    background: #eeeeee;
}
</style>
